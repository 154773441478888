import React from 'react'
import productimage from './img/404error.jpg'
import styled from 'styled-components'

const ProductgroupImage = styled.div`
    background-image: url(${productimage});
    height: 320px;
    background-repeat: no-repeat;
    background-position: center;
`

const NotFound = () => {
  return (
    <ProductgroupImage />
  )
}

export default NotFound
