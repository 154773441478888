import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './common/Button'
import config from './common/ApiHandler/Config'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Translation, { ContentLanguage } from './common/BasicTranslation'
import useAxios from 'axios-hooks'

const Background = styled.div`
  background: #ffffff;
  flex-grow: 1;
  margin: 0;
  padding: 0 20px;
`

const ProductgroupTitle = styled.div`
  margin-top: 30px;
  font-size: 30px;
  font-family: "ASSA Vesta", sans-serif !important;
  font-weight: bold;
`

const ProductsubgroupTitle = styled.div`
  margin-top: 10px;
  font-family: "ASSA Vesta", sans-serif !important;
  font-size: 16px;
`

const ProductgroupDescription = styled.div`
    margin: 25px 0;
`

const ButtonNavigation = styled.div`
    margin: 25px 0;
`

const ProductVideo = styled.iframe`
  height: auto;
  width: 100%;
  min-height: 250px;
`

const SectionTitle = styled.div`
  margin-top: 30px;
  font-family: "ASSA Vesta", sans-serif !important;
  font-size: 18px;
`

const DownloadContainer = styled.div`
  height: 60px;
  border: 1px solid #C9C9C9;
  background-color: #FFFFFF;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`
const DownloadFiletype = styled.div`
  display: inline-block;
  background-color: #00A0D0;
  color: white;
  border: 1px #00A0D0 solid;
  border-radius: 3px;
  font-size: 12px;
  padding: 7px;
  margin-left: 13px;
`

const DownloadFilename = styled.div`
  display: inline-block;
  margin-left: 15px;
  color: #00A0D0;
  font-family: "ASSA Vesta", sans-serif !important;

`

const MSL_FLIPLOCK_ABBREVIATION = 'MSL FlipLock'

const Productsubgroup = (props) => {
  const [isActive, setActive] = useState(true)

  const shortId = props.match.params.productsubgroupid
  const [{ data = {} }] = useAxios(`/products/subgroup/${shortId}`)

  React.useEffect(() => {
    if (!isActive) {
      window.scrollTo(0, 0)
      setActive(true)
    }
  }, [isActive])

  function handleClick (which) {
    setActive(false)
    props.history.push(`/psg/${which}`)
  }

  const renderFaq = (faqType = 'generalFaq') => {
    const faqList = Translation(faqType)
    if (!faqList.length) {
      return
    }
    return (
      <Accordion>
        {faqList.map((faq, index) => (
          <Card key={`card_${index}`}>
            <Accordion.Toggle as={Card.Header} eventKey={index}>
              {faq.question}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
              <Card.Body>
                {
                  faq.link
                    ? (
                      <a href={faq.link} rel='noopener noreferrer' target='_blank' download>
                        <DownloadContainer>
                          <DownloadFiletype>{faq.filetype || faq.link.split('.').pop().toUpperCase()}</DownloadFiletype>
                          <DownloadFilename>{faq.filename}</DownloadFilename>
                        </DownloadContainer>
                      </a>
                      )
                    : faq.answer
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    )
  }

  const { product = {}, subgroups = [] } = data
  const currentSubgroup = subgroups.find((group) => group.id === shortId || group.shortId === shortId) || {}
  const { documents = [] } = currentSubgroup
  const files = documents.filter(({ file, ...titles }) => (file && titles[`title_${ContentLanguage}`]))
  const youtubeLinks = documents?.filter(({ youtube, ...titles }) => (youtube && titles[`title_${ContentLanguage}`]))

  return (
    <Background>
      <ProductgroupTitle>{product[`title_${ContentLanguage}`] || product.title_en || product.title_de || product.title_fr || product.title_it}</ProductgroupTitle>
      <ProductsubgroupTitle>{currentSubgroup[`title_${ContentLanguage}`] || currentSubgroup.title_en || currentSubgroup.title_de}</ProductsubgroupTitle>
      <ProductgroupDescription className={youtubeLinks.length ? '' : 'hidden'}>
        {Translation('installationvideo')}
      </ProductgroupDescription>
      {youtubeLinks.map((document, index) => {
        return (
          <ProductVideo key={`ProductVideo_${index}`} src={'https://www.youtube.com/embed/' + document.youtube} frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen />
        )
      })}
      <SectionTitle className={documents.length ? '' : 'hidden'}>{Translation('further_downloads')}</SectionTitle>
      {files.map((document, index) => {
        return (
          <a key={`DownloadContainer_${index}`} href={config.backend + '/download/' + document.id} rel='noopener noreferrer' target='_blank' download>
            <DownloadContainer>
              <DownloadFiletype>PDF</DownloadFiletype>
              <DownloadFilename>{document[`title_${ContentLanguage}`] || document.title_en || document.title_de}</DownloadFilename>
            </DownloadContainer>
          </a>
        )
      })}

      {ContentLanguage !== 'en' && data && data.product && data.product.abbreviation === MSL_FLIPLOCK_ABBREVIATION && (
        <>
          <SectionTitle>FAQ</SectionTitle>
          <ProductgroupDescription>
            {Translation('general_questions')}
          </ProductgroupDescription>
          {renderFaq('generalFaq')}

          <ProductgroupDescription>
            {Translation('question_fliplock')}
          </ProductgroupDescription>
          {renderFaq('fliplockFaq')}
        </>
      )}

      <SectionTitle>{Translation('further_products')}</SectionTitle>
      <ButtonNavigation>
        {subgroups.map((subgroup) => {
          return subgroup[`title_${ContentLanguage}`] || subgroup.title_en || subgroup.title_de !== currentSubgroup[`title_${ContentLanguage}`] || currentSubgroup.title_en || currentSubgroup.title_de
            ? <Button type='primary' key={subgroup[`title_${ContentLanguage}`] || subgroup.title_en || subgroup.title_de.toLowerCase()} onClick={() => handleClick(subgroup.id || subgroup.short_id)}>{subgroup[`title_${ContentLanguage}`] || subgroup.title_en || subgroup.title_de}</Button>
            : ''
        })}
      </ButtonNavigation>
    </Background>
  )
}

export default Productsubgroup
