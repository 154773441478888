import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { configure } from 'axios-hooks'
import Axios from 'axios'
import config from './common/ApiHandler/Config'

import Productgroup from './Productgroup'
import Productsubgroup from './Productsubgroup'
import Header from './common/Header'
import Footer from './common/Footer'
import NotFound from './NotFound'

// configure base url for useAxios fetch hook
const axios = Axios.create({
  baseURL: config.backend
})
configure({ axios })

function App () {
  return (
    <Router basename={window.location.pathname + '#'}>
      <Header />
      <Switch>
        <Route path='/' exact render={() => <Redirect to='/pg/01' />} />
        <Route path='/pg/:productgroupid' component={Productgroup} />
        <Route path='/psg/:productsubgroupid' component={Productsubgroup} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  )
}

export default App
