const translations = {
  de: {
    installationvideo: 'Nachfolgend zeigen wir Ihnen, wie das Produkt fachgerecht montiert wird.',
    further_downloads: 'Downloads:',
    general_questions: 'Allgemeine Fragen',
    question_fliplock: 'Fragen zur FlipLock-Linie',
    further_products: 'Weitere Produkte:',
    generalFaq: [
      {
        question: 'Welche Panikfunktionen gibt es und wie funktionieren diese?',
        link: 'https://www.assaabloy.ch/Other/AA%20Schweiz/05%20Download/Erkl%c3%a4rung%20Panikfunktionen.pdf',
        filename: 'Panikfunktionen'
      },
      {
        question: 'Bei welchen Schlössern sind Drehknopfzylinder zugelassen?',
        link: 'https://www.assaabloy.ch/Other/AA%20Schweiz/05%20Download/Zylinderfreigabuebersicht.pdf',
        filename: 'Zylinderfreigabübersicht'
      },
      {
        question: 'Wo kann ich MSL Schlösser und Zubehör bestellen?',
        answer: 'Unsere Produkte sind ausschliesslich über den Fachhändler erhältlich.'
      },
      {
        question: 'Wieviel beträgt die Falzluft für die jeweiligen MSL Schlösser?',
        answer: 'Unsere Produkte sind ausschliesslich über den Fachhändler erhältlich.'
      },
      {
        question: 'Darf ein Bauschlüssel beim Einbau von MSL Produkten verwendet werden?',
        answer: 'Beim Einbau eines MSL Schlosses, ist die Vewendung eines Bauschlüssels untersagt und absolut zu unterlassen.'
      },
      {
        question: 'Wie bestimme ich die DIN Richtung?',
        link: 'https://www.assaabloy.ch/Other/AA%20Schweiz/05%20Download/Erkl%c3%a4rung%20DIN%20Richtung.pdf',
        filename: 'DIN Richtung'
      }
    ],
    fliplockFaq: [
      {
        question: 'Wie funktioniert das ein- und ausschalten der Tagesfunktion?',
        link: 'https://www.assaabloy.ch/Other/AA%20Schweiz/05%20Download/Zuschalten_Tagesfunktion_TF_FlipLock_und_sFlipLock.pdf',
        filename: 'Tagesfunktion'
      },
      {
        question: 'Wie schalte ich die Panikfunktion B ein und aus?',
        link: 'https://www.assaabloy.ch/Other/AA%20Schweiz/05%20Download/Zuschalten_Panik_B_Funktion.pdf',
        filename: 'Panikfunktion B'
      },
      {
        question: 'Wie montiere ich die XL Stulpverlängerung?',
        link: 'https://www.assaabloy.ch/Other/AA%20Schweiz/05%20Download/Montage_XL_Verlngerung_Flip_D.pdf',
        filename: 'Montage XL Verlängerung'
      },
      {
        question: 'Wie schalte ich die FlipLock Tagesfunktion der Gegenbascule ein/aus (2 flüglig)?',
        link: 'https://www.assaabloy.ch/Other/AA%20Schweiz/05%20Download/Bedienung_Tagesfunktion_ZF_Gegenbascule.Hd.pdf',
        filename: 'Tagesfunktion Gegenbascule'
      },
      {
        question: 'Warum wird die Flip(-Kipp) Falle über den Wechsel nicht in den Kasten zurückgezogen?',
        answer: 'Die Flip(-Kipp) Falle wird dadurch für eine Bewegung freigegeben und kann somit unter hoher Vorlast geöffnet werden.'
      },
      {
        question: 'Hat der Fliplock Drive eine zentrale Schaltuhr mit TA/NA Funktion?',
        answer: 'Ja'
      },
      {
        question: 'Was ist eine Tagesfunktion beim FlipLock?',
        answer: 'Die Tagesfunktion erlaubt es, die Tür beidseitig zu begehen, da nun der Kippfallenriegel weich geschaltet ist analog einer Rollfalle.'
      }
    ]
  },
  en: {
    installationvideo: 'In the following, we show you how to install the product professionally.',
    further_downloads: 'Downloads:',
    general_questions: 'General questions',
    question_fliplock: 'Questions relating to the FlipLock line',
    further_products: 'Further products:'
  },
  fr: {
    installationvideo: 'Dans ce qui suit, nous vous montrerons comment installer le produit de manière professionnelle.',
    further_downloads: 'Téléchargements:',
    general_questions: 'Question générales',
    question_fliplock: 'Ques­tions sur la game Fli­p­Lock',
    further_products: 'Autres produits :',
    generalFaq: [
      {
        question: 'Il ya quelle fonctione de anti­panique et comment fonctionne-t-elle?',
        link: 'https://www.assaabloyopeningsolutions.ch/Other/AA%20Schweiz/05%20Download/Explication%20fonction%20anti-panique.pdf',
        filename: 'Fonctions anti-panique'
      },
      {
        question: 'Quelles serrures sont compatibles avec des cylindres à bouton?',
        link: 'https://www.assaabloyopeningsolutions.ch/Other/AA%20Schweiz/05%20Download/Zylinderfreigabuebersicht%20F.pdf',
        filename: 'Autorisation de cylindre'
      },
      {
        question: 'Où commander les serrures et les garnitures de MSL?',
        answer: 'Nos produits sont exclusivement en vente dans le commerce spécialisés.'
      },
      {
        question: 'Combien est le jeu de battu pour les serrures de MSL?',
        answer: 'Casa: 3-6 mm; Delta: 3-6 mm; sFlipLock: 3-6 mm; Tribloc 3-6 mm; FlipLock 3-6 mm'
      },
      {
        question: "Utilisation d'une clé chantier",
        answer: "Pendent le montage d'une serrure, l'utilisation d'une clé chantier est absolutment assenti et interdit!"
      },
      {
        question: 'Désignation des directions DIN',
        link: 'https://www.assaabloyopeningsolutions.ch/Other/AA%20Schweiz/05%20Download/Designation%20direction%20DIN.pdf',
        filename: 'Désignation des directions DIN '
      }
    ],
    fliplockFaq: [
      {
        question: 'Comment activer la fonction journalière?',
        link: 'https://www.assaabloyopeningsolutions.ch/Other/AA%20Schweiz/05%20Ueber%20uns/Zuschalten_Tagesfunktion_TF_FlipLock_und_sFlipLock.pdf',
        filename: 'Fonction journalière'
      },
      {
        question: 'Comment on peut activer ou déactiver la fonction antipanique B?',
        link: 'https://www.assaabloyopeningsolutions.ch/Other/AA%20Schweiz/05%20Download/Zuschalten_Panik_B_Funktion.pdf',
        filename: 'Fonction antipanique B'
      },
      {
        question: 'Comment activer la fonction journalière du contre bascule (2 vantaux)?',
        link: 'https://www.assaabloyopeningsolutions.ch/Other/AA%20Schweiz/05%20Download/Activer_fonction_journalire_TF.pdf',
        filename: 'Fonction journalière'
      },
      {
        question: 'La FlipLock Drive à t’elle une sortie pour une minuterie centralisée avec affichage TA/NA( jour / nuit)?',
        answer: 'Oui.'
      },
      {
        question: "Qu'est-ce que c'est la fonction journalière de FlipLock?",
        answer: "La fonction journalière permets l'accessibilité de la porte des deux cotés. Les becs de cane Flip(pivotant) sont branchés malléables, analogue à un bec de cane à rouleau."
      },
      {
        question: 'Pourquoi les becs de cane Flip(-pivotants) ne se retirent pas dans le coffre de la serrure avec la fonction de levier?',
        answer: 'C’est le mouvement des becs de cane Flip(-pivotants) qui est libéré. Cela permet une ouverture facile même sous pression.'
      }
    ]
  }
}

export default translations
