import React from 'react'
import logo from './img/logo.svg'
import styled from 'styled-components'

const StyledHeader = styled.header`
  height: 60px;
  padding: 0px 20px 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: 5px solid #00a0d0;
`

const StyledLogo = styled.img`
  display: inline-block;
  max-height: 25px;
`

function Header () {
  return (
    <StyledHeader>
      <StyledLogo src={logo} alt='logo' />
    </StyledHeader>
  )
}

export default Header
