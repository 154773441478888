import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  appearance: none;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border: 1px solid #cccccc;
  opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
  ${({ buttonType }) => (buttonType === 'primary')
? `
    height: 60px;
    width: 100%;
    background-color: #FFFFFF;
    color: #00a0d0;
    line-height: 20px;
    text-align: left;
    font-size: 20px;
    border-bottom: 3px solid #00a0d0;
    margin-bottom: 15px;
  `
: ''}
`

const Button = ({ type: buttonType, isDisabled, children, ...restProps }) => {
  return (
    <StyledButton
      type='submit'
      disabled={isDisabled}
      buttonType={buttonType}
      {...restProps}
    >
      {children}
    </StyledButton>
  )
}

export default Button
