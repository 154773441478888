import React, { useState, useEffect } from 'react'
import defaultImage from './img/installationsvideo_bild.jpg'
import styled from 'styled-components'
import Button from '../common/Button'
import { withRouter, Redirect } from 'react-router-dom'
import Translation, { ContentLanguage } from '../common/BasicTranslation'
import useAxios from 'axios-hooks'
import config from '../common/ApiHandler/Config'
import ReactMarkdown from 'react-markdown'

const Background = styled.div`
  background: #ffffff;
  flex-grow: 1;
  margin: 0;
  width: 100%;
`

const ContentBackground = styled.div`
  background: #ffffff;
  flex-grow: 1;
  margin: 0;
  padding: 0 20px;
`

const ProductgroupContainer = styled.div`
  height: 100%;
  max-width: 100vw;
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  align-items: center;
`

const ProductgroupImage = styled.img`
  width: 100vw;
  z-index: 1;
`

const ProductgroupDescription = styled.div`
  padding: 0 20px;
  margin: 25px 0;
`

const ButtonNavigation = styled.div`
  padding: 0 20px;
  margin: 25px 0;
`

const ProductNameContainer = styled.div`
  position: absolute;
  max-height: 20%;
  height: 20%;
  max-width: 210px;
  min-width: 180px;
  min-height: 120px;
  background: rgba(0,160,208,.8) !important;
  color: #ffffff !important;
  font-size: 20px;
  z-index: 20;
  display: flex;
  align-items: center;
`

const ProductName = styled.p`
  padding: 20px;
  font-family: "ASSA Vesta", sans-serif !important;
  font-weight: normal;
  margin-bottom: 0px;
`

const ProductVideo = styled.iframe`
  height: auto;
  width: 100%;
  min-height: 250px;
`

const SectionTitle = styled.div`
  margin-top: 30px;
  font-family: "ASSA Vesta", sans-serif !important;
  font-size: 18px;
`

const DownloadContainer = styled.div`
  height: 60px;
  border: 1px solid #C9C9C9;
  background-color: #FFFFFF;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`
const DownloadFiletype = styled.div`
  display: inline-block;
  background-color: #00A0D0;
  color: white;
  border: 1px #00A0D0 solid;
  border-radius: 3px;
  font-size: 12px;
  padding: 7px;
  margin-left: 13px;
`

const DownloadFilename = styled.div`
  display: inline-block;
  margin-left: 15px;
  color: #00A0D0;
  font-family: "ASSA Vesta", sans-serif !important;
`

function Productgroup ({ match, history }) {
  const [keyVisual, setKeyVisual] = useState(defaultImage)
  const shortId = match.params.productgroupid
  const [{ data = {} }] = useAxios(`/products/short-id/${shortId}`)

  const { product = {}, documents = [], subgroups = [] } = data
  const files = documents.filter(({ file, ...titles }) => (file && titles[`title_${ContentLanguage}`]))
  const youtubeLinks = documents.filter(({ youtube, ...titles }) => (youtube && titles[`title_${ContentLanguage}`]))

  useEffect(() => {
    if (!data.product || !data.product.hasImage) {
      return setKeyVisual(defaultImage)
    }

    setKeyVisual(`${config.backend}${data.product.imageUrl}`)
  }, [data])

  function handleClick (which) {
    history.push(`/psg/${which}`)
  }

  if (shortId && shortId.length === 1) {
    return <Redirect to={'/pg/' + shortId.padStart(2, '0')} />
  }

  return (
    <Background>
      <ProductgroupContainer>
        <ProductgroupImage src={keyVisual} alt='ProductBanner' />
        <ProductNameContainer>
          <ProductName>
            {product[`title_${ContentLanguage}`]}
          </ProductName>
        </ProductNameContainer>
      </ProductgroupContainer>
      <ProductgroupDescription>
        {product &&
          <ReactMarkdown>
            {product[`description_${ContentLanguage}`]}
          </ReactMarkdown>}
      </ProductgroupDescription>
      <ProductgroupDescription className={youtubeLinks.length ? '' : 'hidden'} />
      <ContentBackground>
        {youtubeLinks.map((document) => {
          return (
            <ProductVideo
              key={document.id}
              src={'https://www.youtube.com/embed/' + document.youtube}
              frameborder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            />
          )
        })}
        <SectionTitle className={documents.length ? '' : 'hidden'}>
          {Translation('further_downloads')}
        </SectionTitle>
        {files.map((document) => {
          return (
            <a
              href={config.backend + '/download/' + document.id}
              rel='noopener noreferrer'
              target='_blank'
              download
              key={document.id}
            >
              <DownloadContainer>
                <DownloadFiletype> PDF </DownloadFiletype>
                <DownloadFilename>
                  {document[`title_${ContentLanguage}`] || document.title_en || document.title_de || document.title_fr || document.title_it}
                </DownloadFilename>
              </DownloadContainer>
            </a>
          )
        })}
      </ContentBackground>
      <ButtonNavigation>
        {subgroups.map((subgroup, index) => (
          <Button
            type='primary'
            key={`${subgroup.shortId}_${index}`}
            onClick={() => handleClick(subgroup.id || subgroup.short_id)}
          >
            {subgroup[`title_${ContentLanguage}`] || subgroup.title_en || subgroup.title_de || subgroup.title_fr || subgroup.title_it}
          </Button>
        ))}
      </ButtonNavigation>
    </Background>
  )
}

export default withRouter(Productgroup)
