import translations from './Translation'

const SUPPORTED_LANGUAGES = ['de', 'en', 'fr', 'it']

const Translation = (res) => {
  const userLang = getLanguage()

  if (translations[userLang][res]) {
    const translatedRes = translations[userLang][res]
    return translatedRes
  } else {
    return res
  }
}

const getLanguage = () => {
  let userLang = navigator.language || navigator.userLanguage

  if (userLang.indexOf('-') > -1) {
    userLang = userLang.substring(0, userLang.indexOf('-'))
  }

  if (!translations[userLang] || !SUPPORTED_LANGUAGES.includes(userLang)) {
    userLang = 'en'
  }

  return userLang
}

const getContentLanguage = () => {
  let language = navigator.language || navigator.userLanguage

  if (language.indexOf('-') > -1) {
    language = language.substring(0, language.indexOf('-'))
  }

  return language
}

export default Translation
export const Language = getLanguage()
export const ContentLanguage = getContentLanguage()
