import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  border-top: 5px solid #00a0d0;
  padding: 0 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`

function Footer (props) {
  return (

    <StyledFooter>
      {window.location.hash.substr(2) === 'pg/04'
        ? (
          <a href='http://www.planet.ag/' rel='noopener noreferrer' target='_blank'>PLANET GDZ AG</a>
          )
        : (
          <a href='https://www.assaabloy.ch' rel='noopener noreferrer' target='_blank'>ASSA ABLOY Schweiz</a>
          )}<br /><br />

      <a href='https://www.assaabloy.ch/de/local/schweiz/kontakt/' rel='noopener noreferrer' target='_blank'>Kontakt</a> <br />
      <a href='https://www.assaabloy.ch/de/local/schweiz/handelspartner/partner-schweiz/' rel='noopener noreferrer' target='_blank'>Partner Schweiz</a> <br />
      <a href='https://www.assaabloy.ch/de/local/schweiz/handelspartner/partner-international/' rel='noopener noreferrer' target='_blank'>Partner International</a> <br />
      <hr />
      &copy; ASSA ABLOY <a href='https://www.assaabloy.ch/de/local/schweiz/menu/impressum/' rel='noopener noreferrer' target='_blank'>Impressum</a> | <a href='https://www.assaabloy.ch/de/local/schweiz/menu/datenschutz-center/' rel='noopener noreferrer' target='_blank'>Datenschutz</a><br /><br />
      Experience a safer and more open world
    </StyledFooter>
  )
}

export default Footer
